/* RequestSignUpLink.css */

/* Zentrieren der Komponente */
.centered-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  /* Stil für das Anmeldeformular */
  .signup-box {
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 20px;
    width: 90%;
    max-width: 600px;
    text-align: center;
  }
  
  /* Stil für das KITA-Logo */
  .signup-box img {
    width: 250px;
    margin-bottom: 20px;
  }
  
  /* Stil für den Begrüßungstext */
  .signup-box p {
    text-align: center;
  }
  
  /* Stil für die Passwortfelder */
  .signup-box input[type="password"] {
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  
  /* Stil für Fehlermeldungen */
  .signup-box .alert {
    width: 100%;
    text-align: center;
    background-color: #f2dede;
    border: 1px solid #ebccd1;
    color: #a94442;
    border-radius: 3px;
    padding: 10px;
    margin-top: 10px;
  }
  
  /* Stil für den Passwort-Setzen-Button */
  .signup-box button.btn-primary {
    width: 100%;
    margin-top: 10px;
  }
  