/* FinishSignUpEmployee.css */

.signup-container {
    width: 90%;
    max-width: 600px;
}

.password-input {
    width: 100%;
    max-width: 250px;
}

.signup-button {
    width: 100%;
    max-width: 250px;
}

@media (min-width: 768px) {
    .password-input {
        width: 250px;
    }

    .signup-button {
        width: 250px;
    }
}
