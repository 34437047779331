.weekday-tile {
    padding: 10px;
    border: 1px solid #ccc; /* Grauer Rahmen */
    border-radius: 5px; /* Abgerundete Ecken */
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s; /* Sanfter Übergang für den Hintergrund */
    margin-right: 10px;
  }
  
  .weekday-tile:hover {
    background-color: #e0f0ff; /* Hellblauer Hintergrund beim Hover */
  }
  
  .selected {
    background-color: #007bff; /* Blauer Hintergrund wenn ausgewählt */
    color: white; /* Weißer Text wenn ausgewählt */
  }
  
  .weekday-row {
    display: flex;
    align-items: center;
  }
  
  .weekday-dropdown {
    display: block; /* Stellen Sie sicher, dass das Dropdown-Menü angezeigt wird */
    /* Weitere Stile für das Dropdown-Menü nach Bedarf */
  }
  
  .form-group-custom .form-label {
    display: inline-block;
    width: 150px; /* oder eine Breite nach Wahl, um alle Labels gleich breit zu machen */
    margin-right: 1rem; /* Abstand zum nächsten Element */
    vertical-align: top; /* Um sicherzustellen, dass das Label oben ausgerichtet wird, falls der Datepicker höher ist */
  }
  
  /* Sie könnten auch die Breite der Controls anpassen, falls notwendig */
  .form-group-custom .form-control, 
  .form-group-custom .datepicker {
    width: auto; /* oder eine spezifische Breite, falls gewünscht */
    display: inline-block; /* damit es neben dem Label steht */
  }

  /* Für Fehlermeldungen (alert-danger) */
.alert.alert-danger {
    background-color: #f8d7da;
    border-color: #f5c6cb;
    color: #721c24;
  }
  
  /* Für Erfolgsmeldungen (alert-success) */
  .alert.alert-success {
    background-color: #d4edda;
    border-color: #c3e6cb;
    color: #155724;
  }
  