.child-card-not-active {
  border: 2px solid #ff9999; /* Hellrote Umrandung */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px; /* Set a fixed height */
}

.child-card-active {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px; /* Set a fixed height */
}

.child-profile-pic {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
}
