.date-picker-container {
    display: flex;
    align-items: center;
}

.date-picker-input .form-control.narrower-input {
    width: auto; /* Das Datumsfeld auf die erforderliche Breite begrenzen */
}

/* Überschreiben Sie die Bootstrap-Stile für Zeilen in der Tabelle */
.table tbody tr {
    background-color: white; /* Standard-Hintergrundfarbe */
  }
  
  /* Überschreiben Sie die Zeilenhintergrundfarbe für Zeilen mit child.checkin === true */
  .table tbody tr[data-checkin="true"] {
    background-color: lightgreen; /* Ihre benutzerdefinierte Hintergrundfarbe für Zeilen mit child.checkin === true */
    --bs-table-bg: none;
  }
  

  .closing-info {
    display: block; /* Stellt sicher, dass der Text auf einer eigenen Zeile angezeigt wird */
    margin-top: 10px; /* Fügt einen oberen Abstand von 10 Pixeln hinzu (passen Sie den Wert nach Bedarf an) */
    font-weight: bold; /* Fügt Fettdruck hinzu, um den Text hervorzuheben (optional) */
    color: #333; /* Ändert die Textfarbe (optional) */
    /* Weitere Stilregeln nach Bedarf hinzufügen */
  }
  