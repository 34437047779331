.edit-icon-overlay {
    position: absolute;
    right: 10px; /* Anpassung für den Abstand von der rechten unteren Ecke */
    bottom: 10px; /* Anpassung für den Abstand von der rechten unteren Ecke */
    border-radius: 50%; /* Macht das Overlay kreisförmig */
    width: 40px; /* Größe des Kreises */
    height: 40px; /* Größe des Kreises */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent; /* Hintergrund anfangs transparent */
    cursor: pointer; /* Cursor als Zeiger anzeigen */
    transition: background-color 0.2s; /* Sanfter Übergang für die Hintergrundfarbe */
  }
  
  .edit-icon-overlay:hover {
    background-color: rgba(128, 128, 128, 0.5); /* Graue Farbe beim Hover */
  }
  

  /* Standardmäßig sind die Buttons sichtbar */
.desktop-camera-button {
  display: block;
}

/* Verstecken Sie die Buttons auf Bildschirmen kleiner als 1024px Breite */
@media screen and (max-width: 1023px) {
  .desktop-camera-button {
    display: none;
  }
}


.profile-pic-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-pic {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
}
