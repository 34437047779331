.container {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .card-custom {
    flex: 1 1 48%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .card-body-custom {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  
  .card-body-custom-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .profile-pic {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 20px;
  }
  
  .card-title {
    margin-bottom: 20px;
    font-size: 1.5rem;
  }
  
  .info-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 5px 0;
  }
  
  .info-label {
    flex: 0 0 40%;
    font-weight: bold;
  }
  
  .info-value {
    flex: 1;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .container {
      flex-direction: column;
      align-items: center;
    }
  
    .card-custom {
      max-width: 90%;
      width: 90%;
      margin-bottom: 20px;
    }
  }
  